import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "timi-projectlist-container" }
const _hoisted_2 = { class: "timi-projectlist-content" }
const _hoisted_3 = { class: "top-action-bar" }
const _hoisted_4 = { class: "list-header" }
const _hoisted_5 = { class: "list-header-actions" }
const _hoisted_6 = { class: "list-item-actions" }
const _hoisted_7 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Searchbar = _resolveComponent("Searchbar")!
  const _component_OptionButtonGroup = _resolveComponent("OptionButtonGroup")!
  const _component_IconButton = _resolveComponent("IconButton")!
  const _component_ListItem = _resolveComponent("ListItem")!
  const _component_List = _resolveComponent("List")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_Searchbar, { onOnValueChange: _ctx.handleOnChangeSearchInput }, null, 8, ["onOnValueChange"])
      ]),
      _createElementVNode("header", _hoisted_4, [
        _createElementVNode("h3", null, _toDisplayString(_ctx.$t('project.activeProjects')), 1),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_OptionButtonGroup, {
            value: _ctx.displayMode,
            options: _ctx.displayOptions,
            onOnChange: _ctx.handleDisplayChange
          }, null, 8, ["value", "options", "onOnChange"])
        ])
      ]),
      _createVNode(_component_List, {
        isEmpty: _ctx.filteredProjectList.length > 0 ? false : true,
        emptyStateText: _ctx.$t('emptyState.projects'),
        raised: true
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredProjectList, (project) => {
            return (_openBlock(), _createBlock(_component_ListItem, {
              key: project.uid,
              interactive: true
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", null, [
                  _createElementVNode("h4", null, _toDisplayString(_ctx.getProjectTitle(project)), 1),
                  _createElementVNode("p", null, _toDisplayString(project?.customer?.custName), 1)
                ]),
                _createElementVNode("div", _hoisted_6, [
                  (_ctx.sessionCompany?.settings?.defaultProject == project.uid)
                    ? (_openBlock(), _createElementBlock("i", {
                        key: 0,
                        class: "svgicon svgicon--lock",
                        innerHTML: _ctx.store.getters.svgIcons.lock
                      }, null, 8, _hoisted_7))
                    : _createCommentVNode("", true),
                  (_ctx.sessionCompany?.settings?.defaultProject != project.uid && !_ctx.isProjectActive(project))
                    ? (_openBlock(), _createBlock(_component_IconButton, {
                        key: 1,
                        icon: _ctx.store.getters.svgIcons.addProject,
                        onOnClick: ($event: any) => (_ctx.handleAddProject(project))
                      }, null, 8, ["icon", "onOnClick"]))
                    : _createCommentVNode("", true),
                  (_ctx.sessionCompany?.settings?.defaultProject != project.uid && _ctx.isProjectActive(project))
                    ? (_openBlock(), _createBlock(_component_IconButton, {
                        key: 2,
                        icon: _ctx.store.getters.svgIcons.removeProject,
                        onOnClick: ($event: any) => (_ctx.handleRemoveProject(project))
                      }, null, 8, ["icon", "onOnClick"]))
                    : _createCommentVNode("", true),
                  (_ctx.sessionCompany?.settings?.defaultProject != project.uid && _ctx.checkRight('edit_project'))
                    ? (_openBlock(), _createBlock(_component_IconButton, {
                        key: 3,
                        icon: _ctx.store.getters.svgIcons.pencil,
                        onOnClick: ($event: any) => (_ctx.handleEditProject(project))
                      }, null, 8, ["icon", "onOnClick"]))
                    : _createCommentVNode("", true)
                ])
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]),
        _: 1
      }, 8, ["isEmpty", "emptyStateText"])
    ])
  ]))
}